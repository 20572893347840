import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'
import Form from '@/components/common/Form'

const DownloadForm = props => {
  const { file, siteMeta, title, ...other } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        const form = {
          ...data.forms.edges[0].node
        }
        const inputFields = [
          ...data.forms.edges[0].node.inputFields,
          {
            name: 'file',
            type: 'hidden',
            value: `${siteMeta.siteUrl}${file.publicURL}`
          }
        ]
        form.inputFields = inputFields
        return (
          <Root {...other}>
            <Head>
              <Title theme={props.theme}>
                ダウンロードフォーム
              </Title>
            </Head>
            <Main>
              <Body variant="body1" theme={props.theme}>
                ご入力いただいたメールアドレスにファイルダウンロード用のリンクを送信いたします。ダウンロードリンクが届かない場合は、お手数ですがこちらのメールアドレス <strong>info@fvc-planner.com</strong> までお問い合わせください。
              </Body>
              <Form {...form} css={styles.Form} beforeSubmit={beforeSubmit(title)} />
            </Main>
          </Root>
        )
      }}
    />
  )
}

const beforeSubmit = (title) => {
  return (values) => {
    window.dataLayer && window.dataLayer.push({
      event: 'app.conversion',
      eventCategory: title,
      eventAction: values.division,
      eventLabel: values.job
    })
  }
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
    br {
      display: none;
    }
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
  strong {
    color: ${props => props.theme.primary.base};
    font-weight: bold;
  }
`

const styles = {
  Form: css`
    margin-top: 64px;
    @media ${mq.and(mobile)} {
      margin-top: 32px;
    }
    width: 100%;
  `
}

export default ThemeConsumer(DownloadForm)

export const query = graphql`
  query DownloadFormQuery {
    forms: allDownloadFormYaml {
      edges {
        node {
          name
          inputFields {
            name
            label
            required
            type
            value
          }
          action
          netlify
          endpoint
        }
      }
    }
  }
`
