import React from 'react'
import MuiInputLabel from '@material-ui/core/InputLabel'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fonts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const InputLabel = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiInputLabel
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiInputLabel>
  )
}

const getColors = (props) => {
  return {
    default: props.theme.text.base,
    error: props.theme.error
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`
  }
  return {
    InputLabel_root: {
      color: colors.default,
      fontSize: 14,
      fontWeight: 'normal',
      fontFamily: fonts.base,
      padding: '0',
      [media.mobile]: {
        fontSize: 14,
        padding: '0'
      }
    },
    InputLabel_focused: {
      color: `${colors.default} !important`
    },
    InputLabel_error: {
      color: `${colors.error} !important`
    },
    InputLabel_formControl: {
      transform: `translate(0, 34px) scale(1)`,
      [media.mobile]: {
        transform: `translate(0, 28px) scale(1)`
      }
    },
    InputLabel_shrink: {
      transform: `translate(3%, 0px) scale(0.85)`
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('InputLabel_') === 0) {
      filteredClasses[className.replace('InputLabel_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(InputLabel)
), 'InputLabel')
