import React from 'react'
import MuiFormHelperText from '@material-ui/core/FormHelperText'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fonts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const FormHelperText = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiFormHelperText
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiFormHelperText>
  )
}

const getColors = (props) => {
  return {
    default: props.theme.text.base,
    error: props.theme.error
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`
  }
  return {
    root: {
      color: colors.default,
      fontSize: 12,
      fontWeight: 'normal',
      fontFamily: fonts.base,
      padding: '0 16px',
      [media.mobile]: {
        padding: '0'
      }
    },
    error: {
      color: colors.error
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('FormHelperText_') === 0) {
      filteredClasses[className.replace('FormHelperText_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(FormHelperText)
), 'FormHelperText')
