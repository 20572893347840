import React from 'react'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fonts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const FormControlLabel = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiFormControlLabel
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiFormControlLabel>
  )
}

const getColors = (props) => {
  return {
    text: props.theme.text.base
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`
  }
  return {
    FormControlLabel_root: {
      margin: '0 auto',
      display: 'flex',
      fontWeight: 'normal',
      justifyContent: 'center'
    },
    FormControlLabel_label: {
      fontWeight: 'normal',
      fontSize: 16,
      [media.mobile]: {
        fontSize: 14
      },
      fontFamily: fonts.base,
      color: colors.text,
      paddingRight: 16
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('FormControlLabel_') === 0) {
      filteredClasses[className.replace('FormControlLabel_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(FormControlLabel)
), 'FormControlLabel')
