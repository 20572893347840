import React from 'react'
import MuiSelect from '@material-ui/core/Select'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import Input from './Input'
import { fonts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { getThemeColor } from '@/utils/themes'

const Select = props => {
  const { theme, classes, children, className, InputProps, ...other } = props
  return (
    <MuiSelect
      className={className}
      classes={{
        ...get(classes)
      }}
      inputProps={{
        ...InputProps
      }}
      {...other}
      input={(
        <Input />
      )}
    >
      { children }
    </MuiSelect>
  )
}

const getColors = (props, options = {}) => {
  const color = props.color || 'text'
  return {
    default: getThemeColor(color, props.theme)
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`
  }
  return {
    Select_select: {
      fontFamily: fonts.base,
      fontSize: 16,
      [media.mobile]: {
        fontSize: 14
      },
      fontWeight: 'bold',
      color: colors.default
    },
    Select_icon: {
      color: colors.default
    },
    Select_disabled: {}
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('Select_') === 0) {
      filteredClasses[className.replace('Select_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(Select)
), 'Select')
