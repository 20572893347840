import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import Typography from '@/components/common/Typography'
import GhostButton from '@/components/common/GhostButton'

const Dialog = withStyles(theme => ({
  paper: {
    margin: '16px',
    maxWidth: '520px'
  }
}))(MuiDialog)

const DialogContent = styled.div`
  padding: 32px 24px;
`

const Title = styled(Typography)`
  text-align: center;
`

const Message = styled(Typography)`
  margin-top: 20px;
`

const Close = styled(GhostButton)`
  display: block;
  margin: 24px auto 0;
  width: 120px;
`

const Component = props => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogContent>
      <Title variant="h2" color="primary">{props.title}</Title>
      <Message variant="body2" type="contrast">{props.children}</Message>
      <Close type="contrast" onClick={props.onClose}>閉じる</Close>
    </DialogContent>
  </Dialog>
)
export default Component
