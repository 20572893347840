import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const GhostButton = props => {
  const { theme, type, classes, children, className, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="outlined"
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  const color = props.color || 'text'
  return {
    backgroundColor: 'transparent',
    color: getThemeColor(color, props.theme),
    borderColor: getThemeColor(color, props.theme)
  }
}

const styles = props => {
  const styles = getStyles(props, props.theme)
  return {
    root: {
      backgroundColor: styles.background,
      color: styles.color,
      borderColor: styles.color,
      borderRadius: 20,
      borderWidth: 2,
      height: 40,
      padding: '0 24px',
      fontSize: 14,
      fontFamily: fonts.base,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(styles.color, 0.05),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        },
        borderColor: styles.color
      },
      '&:disabled': {
        color: fade(styles.color, 0.6),
        borderColor: fade(styles.color, 0.6)
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(GhostButton))
