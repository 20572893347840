import React from 'react'
import MuiLinearProgress from '@material-ui/core/LinearProgress'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fade } from '@material-ui/core/styles/colorManipulator'

const LinearProgress = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiLinearProgress
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiLinearProgress>
  )
}

export const make = props => {
  return {
    LinearProgress_colorPrimary: {
      backgroundColor: fade(props.theme.primary.base, 0.3)
    },
    LinearProgress_colorSecondary: {
      backgroundColor: fade(props.theme.secondary.base, 0.3)
    },
    LinearProgress_barColorPrimary: {
      backgroundColor: props.theme.primary.base
    },
    LinearProgress_barColorSecondary: {
      backgroundColor: props.theme.secondary.base
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('LinearProgress_') === 0) {
      filteredClasses[className.replace('LinearProgress_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(LinearProgress)
), 'LinearProgress')
