import React from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { getThemeColor } from '@/utils/themes'

const Checkbox = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiCheckbox
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiCheckbox>
  )
}

const getColors = (props) => {
  const color = props.color || 'primary'
  return {
    text: props.theme.text.base,
    checked: getThemeColor(color, props.theme),
    disabled: fade(props.theme.text.base, 0.6)
  }
}

export const make = props => {
  const colors = getColors(props)
  return {
    Checkbox_root: {
      color: `${colors.text} !important`
    },
    Checkbox_checked: {
      color: `${colors.checked} !important`
    },
    Checkbox_disabled: {
      color: `${colors.disabled} !important`
    },
    Checkbox_indeterminate: {},
    Checkbox_colorPrimary: {},
    Checkbox_colorSecondary: {}
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('Checkbox_') === 0) {
      filteredClasses[className.replace('Checkbox_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(Checkbox)
), 'Checkbox')
