import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import * as Input from './Input'
import * as InputLabel from './InputLabel'

const TextField = props => {
  const { theme, classes, children, className, InputProps, ...other } = props
  return (
    <MuiTextField
      className={className}
      classes={{
        ...get(classes)
      }}
      InputProps={{
        ...InputProps,
        classes: {
          ...Input.get(classes)
        }
      }}
      InputLabelProps={{
        classes: {
          ...InputLabel.get(classes)
        }
      }}
      {...other}
    >
      { children }
    </MuiTextField>
  )
}

export const make = props => {
  return {
    ...Input.make(props),
    ...InputLabel.make(props)
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('TextField_') === 0) {
      filteredClasses[className.replace('TextField_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(TextField)
), 'TextField')
