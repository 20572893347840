import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { fonts } from '@/utils/preset'

const Root = styled.div``

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Container = styled.div`
  width: 100%;
  color: ${props => props.theme.text.base};

  margin-top: -32px;
  @media ${mq.and(mobileM, mobileS)} {
    margin-top: -24px;
  }

  & > * {
    margin-top: 32px;
    @media ${mq.and(mobileM, mobileS)} {
      margin-top: 24px;
    }
  }

  > div {
    margin-top: 0;
  }

  h2, h3, h4, h5, h6 {
    color: ${props => props.theme.gray.e};
  }
  
  h2 {
    font-size: 28px;
    line-height: 1.5;
    margin-top: 64px;
    @media ${mq.and(mobileM, mobileS)} {
      font-size: 20px;
      margin-top: 48px;
    }
  }

  h3 {
    font-family: ${fonts.jp};
    font-size: 24px;
    line-height: 1.5;
    @media ${mq.and(mobileM, mobileS)} {
      font-size: 18px;
    }
  }

  h4 {
    font-family: ${fonts.jp};
    font-size: 20px;
    line-height: 1.5;
    @media ${mq.and(mobileM, mobileS)} {
      font-size: 14px;
    }
  }

  h2 + *,
  h3 + * {
    margin-top: 24px;
  }

  h4 + *,
  h5 + * {
    margin-top: 16px;
  }

  table {
    width: 100%;
    border: 1px solid ${props => props.theme.line.base};
    &.layout-auto {
      table-layout: auto;
    }

    thead {
      tr {
        border-color: ${props => props.theme.line.base};
      }
      th, td {
      }
      th {
        white-space: nowrap;
        font-weight: bold;
      }
    }
    tbody {
      th {
        text-align: left;
        background-color: ${props => props.theme.gray.b};
      }
    }
    tr {
      border-bottom: 1px solid ${props => props.theme.line.base};

      > * {
        & + th, & + td {
          @media ${mq.with(mobileM, mobileS)} {
            padding-left: 16px;
          }
        }
        & + td {
          font-weight: normal;
          color: ${props => props.theme.gray.d};
        }
      }
    }
    &.no-head tr:first-of-type {
      border-top: 1px solid ${props => props.theme.line.base};
    }
    th, td {
      border: 1px solid ${props => props.theme.line.base};
      padding: 24px;
      font-size: 14px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        padding: 8px;
      }

      p {
        font-size: inherit;
      }

      > * {
        margin: 8px 0;
      }

      @media ${mq.with(mobileM, mobileS)} {
        &.autowidth-mobile {
          width: auto !important;
        }
        &.nowrap-mobile {
          white-space: nowrap !important;
        }
      }
    }
    td {
      color: ${props => props.theme.gray.e};
      @media ${mq.and(mobileM, mobileS)} {
        width: auto;
        white-space: unset;
      }
    }
  }

  ul,
  ul ul,
  ol ul {
    > li {
      position: relative;
      margin-top: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 7px;
        left: -2.5px;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: ${props => props.theme.primary.base};
      }
      position: relative;
      padding-left: 16px;
      font-size: 16px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }

      &:empty::before {
        display: none;
      }
    }
  }

  ol,
  ul ol,
  ol ol {
    counter-reset: number;

    > li {
      position: relative;
      margin-top: 12px;

      &::before {
        counter-increment: number;
        content: counter(number) '. ';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        font-weight: bold;
        line-height: 1.75;
        color: ${props => props.theme.primary.base};
      }
      position: relative;
      padding-left: 32px;
      font-size: 16px;
      line-height: 1.75;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 14px;
      }

      &:empty::before {
        display: none;
      }
    }
  }

  table {
    li {
      font-size: 14px;
      @media ${mq.and(mobileM, mobileS)} {
        font-size: 12px;
      }
    }
  }

  p {
    font-family: ${fonts.jp};
    font-size: 16px;
    line-height: 1.75;
    @media ${mq.and(mobileM, mobileS)} {
      font-size: 14px;
    }
  }

  hr {
    display: block;
    height: 1px;
    background-color: ${props => props.theme.line.base};
    margin: 48px 0;
    @media ${mq.and(mobileM, mobileS)} {
      margin: 24px 0;
    }
  }

  strong {
    font-weight: bold;
    color: ${props => props.theme.gray.e};
  }
  
  small {
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
    color: ${props => getThemeColor('primary', props.theme)};
  }

  .gatsby-resp-image-wrapper {
    margin: 48px 0;
    max-width: 100% !important;
    @media ${mq.and(mobileM, mobileS)} {
      margin: 24px 0;
    }
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }

  .gatsby-resp-iframe-wrapper {
    margin-top: 24px;
    @media ${mq.and(mobileM, mobileS)} {
      margin-top: 16px;
    }
  }

  .App-Content-anchored {
    div {
      padding-top: 56px;
      margin-top: -56px;
      @media ${mq.and(mobileM, mobileS)} {
        padding-top: 48px;
        margin-top: -48px;
      }
    }
  }
`

const Component = props => {
  const { theme, html, modifier, ...other } = props
  return (
    <Root {...other} >
      <Wrapper>
        <Container modifier={modifier} dangerouslySetInnerHTML={{ __html: props.html }} theme={theme} />
      </Wrapper>
    </Root>
  )
}
export default ThemeConsumer(Component)
