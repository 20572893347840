import React from 'react'
import styled from '@emotion/styled'
import axios from 'axios'
import isEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import LoadingMask from '@/components/common/LoadingMask'
import MessageDialog from '@/components/common/MessageDialog'
import ErrorDialog from '@/components/common/ErrorDialog'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import InputLabel from '@/components/common/InputLabel'
import * as MenuItem from '@/components/common/MenuItem'
import FormControlLabel from '@/components/common/FormControlLabel'
import Checkbox from '@/components/common/Checkbox'
import FormHelperText from '@/components/common/FormHelperText'
import Typography from '@/components/common/Typography'
import LinkButton1 from '@/components/common/LinkButton1'

class Form extends React.Component {
  constructor (props) {
    super(props)
    const state = {
      submitStatus: 0, // 0: idle, 1: sending, 2: error, 3: complete
      values: {
        agree: null
      },
      error: {}
    }
    this.props.inputFields.forEach(field => {
      state.values[field.name] = field.value || ''
      state.error[field.name] = null
    })
    this.state = state
    this.formRef = React.createRef()
  }
  handleChange = (event) => {
    this.setState({
      values: {
        ...this.state.values,
        [event.target.name]: event.target.value
      }
    })
  }
  handleCheckboxChange = (event) => {
    this.setState({
      values: {
        ...this.state.values,
        [event.target.name]: event.target.checked ? event.target.value : null
      }
    })
  }
  onMessageModalClose = () => { this.setState({ submitStatus: 0 }) }
  onErrorModalClose = () => { this.setState({ submitStatus: 0 }) }
  onErrorModalRetry = () => { this.submit() }
  submit = async (event) => {
    if (event) event.preventDefault()
    if (!this.validate()) {
      return
    }
    this.setState({ submitStatus: 1, error: {} })
    const res = await axios.post(this.props.endpoint, {
      ...this.state.values,
      form_name: this.props.name
    }).catch(() => {
      return 'error'
    })
    setTimeout(() => {
      if (res === 'error') {
        this.setState({
          submitStatus: 2
        })
        return
      }
      this.props.beforeSubmit({
        ...this.state.values
      })
      this.setState({ submitStatus: 0 })
      this.formRef.current.submit()
    }, 1000)
  }
  validate () {
    const state = { ...this.state, error: {} }
    let result = true
    this.props.inputFields.forEach(field => {
      result = this.validateRequired(field, state) && result
    })
    if (!result) {
      this.setState(state)
    }
    return result
  }
  validateRequired (field, state) {
    if (field.required && isEmpty(state.values[field.name])) {
      state.error[field.name] = field.type === 'select'
        ? `${field.label}を選択してください。`
        : `${field.label}を入力してください。`
      return false
    }
    return true
  }
  validateEmail (field, state) {
    if (field.type === 'email' && isEmail(state.values[field.name])) {
      state.error[field.name] = 'メールアドレスを入力してください。'
      return false
    }
    return true
  }
  render () {
    const { theme, inputFields, classes, beforeSubmit, ...other } = this.props
    return (
      <Root theme={theme} {...other} ref={this.formRef}>
        <LoadingMask open={this.state.submitStatus === 1} message="送信中..." />
        <MessageDialog title="送信完了" open={this.state.submitStatus === 3} onClose={this.onMessageModalClose}>
          このたびはお問合せいただき、誠にありがとうございました。お送りいただいた内容を確認の上、担当者よりご連絡させていただきます。<br />
          数日経っても連絡が来ない場合は、大変お手数ではございますが、メールアドレス等の入力情報をご確認の上、もう一度フォームよりお問合せ頂きますようお願い申し上げます。
        </MessageDialog>
        <ErrorDialog title="送信エラー" open={this.state.submitStatus === 2} onClose={this.onErrorModalClose} onRetry={this.onErrorModalRetry}>
          フォームを送信できませんでした。ネットワーク状況をご確認の上、再度送信をお願いいたします。
        </ErrorDialog>
        <Container>
          <Fields>
            {inputFields.map((field, index) => {
              let Component
              switch (field.type) {
              case 'select':
                Component = this.renderSelect(field, theme, classes)
                break
              case 'text':
              case 'number':
              case 'email':
                Component = this.renderTextField(field, theme, classes)
                break
              case 'textarea':
                Component = this.renderTextArea(field, theme, classes)
                break
              case 'hidden':
                Component = this.renderHidden(field)
                break
              default:
                break
              }
              return Component
            })}
          </Fields>
          <Privacy>
            <Privacy_Desc variant="body2" theme={theme}>個人情報の取り扱いについて、<a href="/other/privacy/" target="_blank">個人情報保護方針</a>に同意の上で送信ください。</Privacy_Desc>
            <Privacy_Check>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agree"
                    value="checked"
                    checked={this.state.values.agree === 'checked'}
                    onChange={this.handleCheckboxChange}
                  />
                }
                label="同意する"
              />
            </Privacy_Check>
          </Privacy>
          <Submit>
            <Button disabled={this.state.values.agree !== 'checked'} onClick={this.submit} color="primary">送信する</Button>
          </Submit>
        </Container>
      </Root>
    )
  }
  renderSelect (field, theme, classes) {
    return (
      <Control key={field.name} required={field.required} error={!!this.state.error[field.name]}>
        <InputLabel>{field.label}</InputLabel>
        <Select
          value={this.state.values[field.name]}
          onChange={this.handleChange}
          inputProps={{
            name: field.name,
            id: field.name
          }}
        >
          <MenuItem.default value="" classes={{ ...MenuItem.get(classes) }}><Default>選択</Default></MenuItem.default>
          {field.values.map(({ label, value }, index) => (
            <MenuItem.default key={index} value={value} classes={{ ...MenuItem.get(classes) }}>{label}</MenuItem.default>
          ))}
        </Select>
        {this.state.error[field.name] && <FormHelperText>{this.state.error[field.name]}</FormHelperText>}
      </Control>
    )
  }
  renderTextField (field, theme, classes) {
    return (
      <Control key={field.name} error={!!this.state.error[field.name]}>
        <TextField
          required={field.required}
          error={!!this.state.error[field.name]}
          label={field.label}
          value={this.state.values[field.name]}
          onChange={this.handleChange}
          InputProps={{
            name: field.name,
            id: field.name
          }}
        />
        {this.state.error[field.name] && <FormHelperText>{this.state.error[field.name]}</FormHelperText>}
      </Control>
    )
  }
  renderTextArea (field, theme, classes) {
    return (
      <Control key={field.name} error={!!this.state.error[field.name]}>
        <TextField
          required={field.required}
          error={!!this.state.error[field.name]}
          label={field.label}
          value={this.state.values[field.name]}
          onChange={this.handleChange}
          multiline
          rowsMax="20"
          InputProps={{
            name: field.name,
            id: field.name
          }}
        />
        {this.state.error[field.name] && <FormHelperText>{this.state.error[field.name]}</FormHelperText>}
      </Control>
    )
  }
  renderHidden (field) {
    return (
      <input key={field.name} type="hidden" name={field.name} value={field.value} />
    )
  }
}

const getStyles = (props) => {
  return {
    backgroundColor: props.theme.background.base,
    linkColor: props.theme.primary.base
  }
}

const Root = styled(
  React.forwardRef(function Component (props, ref) {
    const { gtm, netlify, children, ...other } = props
    return (
      <form
        {...other}
        data-netlify={netlify ? 'true' : 'false'}
        method="post"
        data-netlify-honeypot="bot-field"
        ref={ref}
      >
        <input type="hidden" name="form-name" value={props.name} />
        <input type="hidden" name="bot-field" />
        {children}
      </form>
    )
  })
)``

const Container = styled.div`
  margin: 0 auto;
  @media ${mq.and(tabletS, mobile)} {
    max-width: 100%;
  }
`

const Fields = styled.div``

const Default = styled.em`
  font-weight: bold;
`

const Privacy = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Privacy_Desc = styled(Typography)`
  margin: 0 auto;
  max-width: 624px;
  text-align: center;

  a {
    text-decoration: underline;
    font-weight: bold;
    color: ${props => getStyles({
      ...props
    }).linkColor};
  }
`

const Privacy_Check = styled.div`
  display: flex;
  justify-content: center;
`

const Submit = styled.div`
  margin-top: 32px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
  text-align: center;
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const Control = styled(FormControl)`
  &:not(:first-of-type) {
    margin-top: 24px;
  }
  width: 100%;
`

const make = props => {
  return {
    ...MenuItem.make(props)
  }
}

export default ThemeConsumer(WithStylesProps(make)(Form))
