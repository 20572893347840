import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import MuiWrapper from '@/hoc/MuiWrapper'
import * as LinearProgress from '@/components/common/LinearProgress'
import Typography from '@/components/common/Typography'

const Dialog = withStyles(theme => ({
  paper: {
    width: '300px'
  }
}))(MuiDialog)

const DialogContent = styled.div`
  padding: 32px;
`

const Message = styled(Typography)`
  text-align: center;
`

const Bar = styled(LinearProgress.default)`
  margin-top: 24px;
`

const LoadingMask = props => (
  <Dialog open={props.open}>
    <DialogContent>
      <Message variant="h5" type="contrast">{props.message}</Message>
      <Bar variants="intermediate" color="secondary" />
    </DialogContent>
  </Dialog>
)

export const make = props => {
  return {
    ...LinearProgress.make(props)
  }
}

export default MuiWrapper(ThemeConsumer(
  LoadingMask
), 'LoadingMask')
