import React from 'react'
import MuiInput from '@material-ui/core/Input'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fonts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { getThemeColor } from '@/utils/themes'

const Input = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiInput
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiInput>
  )
}

const getColors = (props) => {
  const color = props.color || 'primary'
  return {
    text: props.theme.gray.e,
    border: props.theme.line.base,
    focused: getThemeColor(color, props.theme),
    error: props.theme.error,
    hover: props.theme.text.base
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`
  }
  return {
    Input_root: {
      color: colors.text,
      fontFamily: fonts.base,
      fontSize: 14,
      [media.mobile]: {
        fontSize: 14
      },
      '& textarea[readonly]': {
        fontSize: 'inherit',
        lineHeight: '1.5'
      }
    },
    Input_underline: {
      '&:after': {
        borderBottom: `2px solid ${colors.focused}`,
        transformOrigin: 'left center'
      },
      '&:before': {
        borderBottom: `1px solid ${colors.border}`
      },
      '&:hover:before': {
        borderBottom: `2px solid ${colors.hover} !important`,
        '@media (hover: none)': {
          borderBottom: `1px solid ${colors.text}`
        }
      }
    },
    Input_disabled: {
    },
    Input_focused: {
    },
    Input_error: {
      color: colors.error,
      '&:after': {
        borderBottomColor: `${colors.error} !important`
      }
    },
    Input_multiline: {
      padding: 0
    },
    Input_fullWidth: {},
    Input_input: {
      padding: '15px 0',
      [media.mobile]: {
        padding: '8px 0'
      },
      lineHeight: 1.5
    },
    Input_inputMarginDense: {},
    Input_inputMultiline: {},
    Input_inputType: {},
    Input_inputTypeSearch: {}
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('Input_') === 0) {
      filteredClasses[className.replace('Input_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(Input)
), 'Input')
